import { numberToStringWithSpaces } from '../utils'

import DescriptionRow from './programInfoRow'
import { addClinic } from './blocks/clinikDescriptionBlock'

class Item {
  #info;
  #programs;
  #prices;
  constructor(info:any) {
    this.#info = info;
    this.#programs = info.program;
    this.#prices = info.price;
  }
    
    render() {
      const divTag = document.getElementById('dms_info')
      if (divTag === null) {
        return
      }

      const titleH3 = document.createElement('h3');
      titleH3.classList.add("text-em-dark","mb-8");
      titleH3.appendChild(document.createTextNode(`«${this.#info.name}»`));

      divTag.appendChild(titleH3);
      
      /* Выводим программу страхования */
      const subTitle = document.createElement('b');
      subTitle.classList.add("text-em-blue");
      subTitle.appendChild(document.createTextNode("Программа страхования"));
      const subTitleTotalSum = document.createElement('b');
      subTitleTotalSum.classList.add("text-em-dark","block","mt-6","mb-2");
      subTitleTotalSum.appendChild(document.createTextNode("Страховая сумма"));
      const totalSum = document.createElement('span');
      totalSum.classList.add("text-em-dark","block");
      totalSum.appendChild(document.createTextNode(`${numberToStringWithSpaces(this.#info.medical_sum.total)}`));
      const medicalSumList = document.createElement('ul');
      medicalSumList.classList.add('border-l-2','border-em-blue','pl-2','mt-4');
      this.#programs.forEach((item:any) => {
        const medicalSumItem = document.createElement('li');
        medicalSumItem.classList.add('mb-2','text-[16px]','text-em-dark');
        medicalSumItem.appendChild(document.createTextNode(`${item.title}`));
        medicalSumList.appendChild(medicalSumItem);
      });
      
      divTag.appendChild(subTitle);
      divTag.appendChild(subTitleTotalSum);
      divTag.appendChild(totalSum);
      divTag.appendChild(medicalSumList);

      /* Выводим срок страхования */
      const subTitleTermInsurance = document.createElement('b');
      subTitleTermInsurance.classList.add('text-em-blue','block','mt-6','mb-2');
      subTitleTermInsurance.appendChild(document.createTextNode("Срок страхования"));
      const termInsurance = document.createElement('span');
      termInsurance.classList.add('text-em-dark','block');
      termInsurance.appendChild(document.createTextNode('1 год'));
      
      divTag.appendChild(subTitleTermInsurance);
      divTag.appendChild(termInsurance);

      /* Выводим стоимость полиса */
      const subTitlePrice = document.createElement('b');
      subTitlePrice.classList.add('text-em-blue','block','mt-6','mb-2');
      subTitlePrice.appendChild(document.createTextNode("Стоимость полиса"));
      
      divTag.appendChild(subTitlePrice);
      
      this.#prices.forEach((item:any, index:number) => {
        const priceList = document.createElement('ul')
        priceList.classList.add('flex','flex-row','justify-between','w-3/4','text-em-dark');
        if (index % 2 !== 0) {
          priceList.classList.add('mt-3');
        }
        const priceAge = document.createElement('li');
        priceAge.appendChild(document.createTextNode(`${item.age_min} - ${item.age_max}`));
        const price = document.createElement('li');
        price.classList.add('font-bold');
        price.appendChild(document.createTextNode(`${numberToStringWithSpaces(item.price)}`));
        priceList.appendChild(priceAge);
        priceList.appendChild(price);

        const saleList = document.createElement('ul')
        saleList.classList.add('flex','flex-row','justify-between','w-3/4','text-em-dark', 'mt-2');
        if (index % 2 === 0) {
          saleList.classList.add('border-b-2','border-b-gray-200','pb-2');
        }
        const saleText = document.createElement('li');
        saleText.appendChild(document.createTextNode('беспроцентная рассрочка'));
        saleText.classList.add('text-em-blue','text-[10px]', 'w-8', 'leading-3');
        const salePrice = document.createElement('li');
        salePrice.classList.add('font-bold');
        salePrice.appendChild(document.createTextNode(`${numberToStringWithSpaces(Math.round(item.price/12),',')} x 12мес.`));
        salePrice.classList.add('text-em-blue','text-[10px]', 'rounded-full', 'border-em-blue', 'px-4','py-1', 'border', 'flex', 'items-center');

        saleList.appendChild(saleText);
        saleList.appendChild(salePrice);

        divTag.appendChild(priceList);
        divTag.appendChild(saleList);
      })

      const taxDeductionInfo = document.createElement('p');
      taxDeductionInfo.classList.add('text-gray-600','text-[12px]','mt-4')
      if (this.#prices[0].tax_deduction) {
        taxDeductionInfo.appendChild(document.createTextNode(`*При оформлении налогового вычета сумма возврата составит от ${numberToStringWithSpaces(this.#prices[0].tax_deduction)} ₽`))
      } else {
        taxDeductionInfo.appendChild(document.createTextNode(`*Возможно оформление налогового вычета`))
      }
      
      divTag.appendChild(taxDeductionInfo);

      /* Отображаем ссылку на документ */
      const documentLink = document.createElement('a');
      documentLink.setAttribute('target', '_blank');
      documentLink.setAttribute('href', `${process.env.DOMAIN}${this.#info.file_program_path}`);
      documentLink.setAttribute('data-test-label', `test_dms_police_doc`)
      const documentLinkDiv = document.createElement('div');
      documentLinkDiv.classList.add('flex','flex-row','items-center','mt-8')
      const documentLinkImg = document.createElement('img');
      documentLinkImg.classList.add('w-8','mr-4');
      documentLinkImg.setAttribute('src','images/pdfico.png');
      documentLinkImg.setAttribute('alt','PDF Icon');
      const documentLinkText = document.createElement('div');
      documentLinkText.appendChild(document.createTextNode(`${this.#info.name}`))
      documentLinkDiv.appendChild(documentLinkImg);
      documentLinkDiv.appendChild(documentLinkText);
      documentLink.appendChild(documentLinkDiv);

      divTag.appendChild(documentLink);

      /* Отображаем ссылку на для перехода на оплату */
      const buyButton = document.createElement('a');
      buyButton.classList.add('bg-em-light-blue','text-white','text-center','w-full','py-4','inline-block','rounded-xl','mt-8');
      buyButton.setAttribute('target', '_blank');
      buyButton.setAttribute('href', `${process.env.DOMAIN}/order?program=${this.#info.id}`);
      buyButton.setAttribute('data-test-label', `test_dms_buy_police`)
      buyButton.addEventListener("click", () => {
        if (process.env.MODE !== "development") {
          VK.Retargeting.Event('prog_moidms');
          VK.Retargeting.Add(49526898);
          ym(89551236,'reachGoal','prog_moidms')
        }
      });
      buyButton.appendChild(document.createTextNode('Оформить полис'))

      divTag.appendChild(buyButton);

      this.#programs.forEach((item:any, index: number)=> {
        const description = new DescriptionRow(index+1, this.#info.id, item);
        description.render()
      })

      const divClinicsCardTag = document.createElement('div');
      divClinicsCardTag.classList.add('card','text-left','flex',
        'flex-row','w-full','justify-between','items-start','mb-4','cursor-pointer','expandable-card');
        divClinicsCardTag.style.paddingLeft = "40px"
        divClinicsCardTag.style.paddingRight = "40px"
        divClinicsCardTag.style.paddingTop = "24px"
        divClinicsCardTag.style.paddingBottom = "24px"
      
      divTag.setAttribute('data-test-label',`test_program_description_cliniks`)
      const divCard = document.createElement('div');
      divCard.classList.add('w-full')

      const titleClinics = document.createElement('h3');
      titleClinics.style.cssText += 'text-align:left';
      titleClinics.classList.add('text-em-dark','mb-4', );
      titleClinics.appendChild(document.createTextNode('Список клиник'))

      divCard.appendChild(titleClinics)

      const subTitleClinics = document.createElement('p');
      subTitleClinics.appendChild(document.createTextNode('Наши медицинские учреждения'))
      
      divCard.appendChild(subTitleClinics)

      const divClinicsContent = document.createElement('div')
      divClinicsContent.classList.add('card-more','hidden');

      this.#programs.forEach((item:any, index: number)=> {
        if (!item.list_medical_institutions || item.list_medical_institutions?.length === 0) {
          return
        }
        const clinicTitle = document.createElement('h5');
        clinicTitle.classList.add('text-left','mt-8', 'text-md', 'font-bold');
        clinicTitle.appendChild(document.createTextNode(item.title))
        divClinicsContent.appendChild(clinicTitle)

        const clinicAddressesTable = document.createElement('div');
        clinicAddressesTable.classList.add('grid', 'grid-cols-1','md:grid-cols-2','gap-0','md:gap-6','w-full');
        item.list_medical_institutions?.map( (institution:any) => {
          const clinicBlock = document.createElement('div');
          clinicBlock.classList.add('flex','flex-col','justify-start','w-full','mt-6','col-span-1');
          if (institution.items.length > 1) {
            clinicBlock.classList.add('md:col-span-2');
          }
          addClinic(clinicBlock,institution )

          clinicAddressesTable.appendChild(clinicBlock);
        })
        divClinicsContent.appendChild(clinicAddressesTable);
      })

      divCard.appendChild(divClinicsContent)
      divClinicsCardTag.appendChild(divCard)

      const divArrow = document.createElement('div');
      divArrow.classList.add('w-[150px]','relative');
      const imgArrow = document.createElement('img');
      imgArrow.classList.add('ml-auto','mt-6');
      imgArrow.setAttribute('alt','arrow');
      imgArrow.setAttribute('src','images/right.png');
      divArrow.appendChild(imgArrow);
      
      divClinicsCardTag.appendChild(divArrow)

      const divDecscription = document.getElementById('dms_description')
      if (divDecscription === undefined || divDecscription === null) {
        return;
      }
      divDecscription.appendChild(divClinicsCardTag);
    }
}

export default Item