import Cookies from "js-cookie";
import request from "sync-request";
import "tw-elements";

import { getCityInformationByIp } from './requests'

import { getCities, getCityData, getClinicsData } from "requests/graphql";

const axios = require("axios").default;

import Program from './components/program'

import {
  loadYM,
  loadMailRu,
  loadDA,
  loadBitrix24,
  loadGoogle,
  numberToStringWithSpaces
} from "utils";


let dataDMS:any = null;

const now = new Date();
let current;
if (now.getMonth() === 11) {
  current = new Date(now.getFullYear() + 1, 0, 1);
} else {
   current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
}

const end = new Date(current).getTime();

const daysEl = document.getElementById("timer_days");
const hoursEl = document.getElementById("timer_hours");
const minutesEl = document.getElementById("timer_minutes");
const secondsEl = document.getElementById("timer_seconds");

const seconds = 1000;
const minutes = seconds * 60;
const hours = minutes * 60;
const days = hours * 24;

const x = setInterval(function () {
  let now = new Date().getTime();
  const difference = end - now;

  if (difference < 0) {
    clearInterval(x);
    return;
  }

  daysEl.innerText = Math.floor(difference / days);
  hoursEl.innerText = Math.floor((difference % days) / hours) < 10 ? "0" + Math.floor((difference % days) / hours) : Math.floor((difference % days) / hours);
  minutesEl.innerText = Math.floor((difference % hours) / minutes) < 10 ? "0" + Math.floor((difference % hours) / minutes) : Math.floor((difference % hours) / minutes);
  secondsEl.innerText = Math.floor((difference % minutes) / seconds) < 10 ? "0" + Math.floor((difference % minutes) / seconds) : Math.floor((difference % minutes) / seconds);
}, seconds);

let allCities:any = [];
let allClinics:any = null;

let user_city = "Москва"
let detected_user_city = "";

let mapMarkersListen = false;

// console.log("ENV:", process.env.MODE)
user_city = Cookies.get("user_city") || "Москва";

const user_selector = document.getElementById('city_selector');
if (user_selector) {
  user_selector.innerHTML = "";
  user_selector?.appendChild(document.createTextNode(`г. ${user_city}`))
}


async function detectCityByIp() {
  try {
    const ipInformationData = await getCityInformationByIp()
    if (ipInformationData === undefined || ipInformationData === null) {
      return;
    }
    const ipDataBody = JSON.parse(ipInformationData.toString());
    detected_user_city = ipDataBody.location.data.city;
  } catch (error) {
    console.log('====================================');
    console.log(error);
    console.log('====================================');
  }
}

async function loadedCities() {
   // Загружаем список всех городов
  const citiesData = await getCities()
  if (citiesData?.data?.dms_cities === undefined) {
    return
  }
  const cities = citiesData.data.dms_cities.sort((a:any,b:any) => {
    if ( a.position === null ) {
      return 1;
    }
    if ( b.position === null ) {
      return -1;
    }
    if (a.position !==null && b.position !== null ) {
      if (a.position > b.position) {
        return 1;
      }
      if (a.position < b.position) {
        return -1;
      }
    } else {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
    }

    return 0;
  })
  allCities = cities;
  await detectCityByIp();

  if ( user_city === detected_user_city && user_city !== undefined && user_city !== null) {
    dataDMS = await getCityData( user_city );
    const city_detector:HTMLElement | null = document.querySelector(".city_detector");
    if (city_detector !== null) {
      city_detector.style.display = "none";
    }
    return
  }
  if ( user_city === undefined || user_city === null || user_city === '' ) {
    if (detected_user_city !== undefined && detected_user_city !== null) {
      if (!allCities.find( (item:any) => item.name === detected_user_city)) {
        const cityOkButton:HTMLElement | null = document.querySelector(".city_ok");
        if (cityOkButton !== null) {
          cityOkButton.style.display = "none";
        }
      }
      const city_detector:HTMLElement | null = document.querySelector(".city_detector");
        if (city_detector !== null) {
          city_detector.style.display = "block";
        }
      const user_city_detector = document.querySelector(".user_city_detector");
      if (user_city_detector !== null) {
        user_city_detector.innerHTML = `г. ${detected_user_city}`;
      }
      return;
    } else {
      const city_detector:HTMLElement | null = document.querySelector(".city_detector");
        if (city_detector !== null) {
          city_detector.style.display = "block";
        }
      const user_city_detector = document.querySelector(".user_city_detector");
      if (user_city_detector !== null) {
        user_city_detector.innerHTML = 'г. Москва';
      }
      return;
    }
  } else {
    const city_detector:HTMLElement | null = document.querySelector(".city_detector");
    if (city_detector !== null) {
      city_detector.style.display = "block";
    }
    const user_city_detector = document.querySelector(".user_city_detector");
    if (user_city_detector !== null) {
      user_city_detector.innerHTML = `г. ${user_city}`;
    }
    return;
  }
}

await loadedCities();

let city_clicked_by_user = "";

const user_city_html = document.querySelector(".user_city");

user_city_html?.addEventListener("click", function () {
  const city_detector:HTMLElement | null = document.querySelector(".city_detector");
  if (city_detector !== null) {
    city_detector.style.display = "block";
  }
});

async function applySelectedCity() { 
  const city_detector:HTMLElement | null = document.querySelector(".city_detector");
  if (city_detector !== null) {
    city_detector.style.display = "none";
  }
  const user_city_detector = document.querySelector(".user_city_detector");
  const cityName = user_city_detector?.innerHTML || '';
  if (cityName !== "") {
    const city = cityName.replace('г. ', '')
    const userCityLabel = document.querySelector(".user_city");
    if ( userCityLabel !== null ) {
      userCityLabel.innerHTML = cityName
    }
    Cookies.set("user_city", city);
    dataDMS = await getCityData( city );
    user_city = city;

    showPrograms();
  }
}

const city_ok = document.querySelector(".city_ok");
city_ok?.addEventListener("click", applySelectedCity);

setModelCloseBtns();
function setModelCloseBtns() {
  let close_btns = document.querySelectorAll(".close_modal");
  close_btns.forEach((btn) => {
    btn.addEventListener("click", closeModal);
  });
  let overlays = document.querySelectorAll(".overlay");
  overlays.forEach((overlay) => {
    overlay.addEventListener("click", closeModal);
  });
}
function attachModalListeners(modalElm:any) {
  modalElm.querySelector(".close_modal").addEventListener("click", toggleModal);
  modalElm.querySelector(".overlay").addEventListener("click", toggleModal);
}

function detachModalListeners(modalElm:any) {
  modalElm.querySelector(".close_modal").removeEventListener("click", toggleModal);
  modalElm.querySelector(".overlay").removeEventListener("click", toggleModal);
}

function closeModal() {
  let modals:NodeListOf<HTMLElement> = document.querySelectorAll(".modal");
  modals.forEach((modal) => {
    modal.style.display = "none";
  });
  return;
}

async function toggleModal(e:any) {
  notificate_form.classList.remove("hidden");
  notificate_form_success.classList.add("hidden");
  var modal = e.target.getAttribute("data-modal");
  if (modal == null) {
    closeModal();
    return;
  } else {
    modal = document.querySelector("." + modal);
  }

  var currentState = modal.style.display;

  // If modal is visible, hide it. Else, display it.
  if (currentState === "none") {
    modal.style.display = "block";
    attachModalListeners(modal);

    const list = document.getElementById('cities_list')
    // Проверяем загружен ли уже список городов
    if ( list?.children.length !== 0) {
      return 
    }
    await loadedCities();
    allCities.forEach((item:any, index:number) => {
      const li = document.createElement("li");
      li.classList.add("modal-city");
      li.classList.add("cursor-pointer");
      li.dataset.available = item.active;
      li.appendChild(document.createTextNode(item.name));
      li.addEventListener("click", selectCity);
      list?.appendChild(li);
    });
  } else {
    modal.style.display = "none";
    detachModalListeners(modal);
  }
}

var modal_btns = document.querySelectorAll(".modal_toggle");
modal_btns.forEach((btn) => {
  btn.addEventListener("click", toggleModal);
});

var input = document.getElementById("city_input");
input.onkeyup = function () {
  var filter = input.value.toUpperCase();
  var lis = document.querySelectorAll(".modal-city");
  for (var i = 0; i < lis.length; i++) {
    var name = lis[i].innerHTML;
    if (name.toUpperCase().indexOf(filter) != -1) lis[i].style.display = "list-item";
    else lis[i].style.display = "none";
  }
};

async function selectCity(e:any) {
  const cityName = typeof e === "object" ? e.target.innerHTML : e
  let selected_city = "г. " + cityName;
  city_clicked_by_user = selected_city;
  let is_avialable = typeof e === 'object' ? e.target.getAttribute("data-available") : "true";

  if (is_avialable == "true") {
    let set_location = document.querySelector(".set_location");

    set_location.style.display = "none";
    detachModalListeners(set_location);
    let user_city_html = document.querySelector(".user_city");
    let user_city_detector = document.querySelector(".user_city_detector");
    user_city_html.innerHTML = selected_city;
    user_city_detector.innerHTML = selected_city;


    await applySelectedCity();
    return;
  }
  let set_location = document.querySelector(".set_location");
  set_location.style.display = "none";
  detachModalListeners(set_location);
  let subscribe_to_notification_modal = document.querySelector(".subscribe_to_notification");
  subscribe_to_notification_modal.style.display = "block";
  attachModalListeners(subscribe_to_notification_modal);
}

const selectProgramTopButton = document.getElementById('button_select_police_top');
selectProgramTopButton?.addEventListener('click', sendAnaliticsOnSelectPolisTopButton );

const selectProgramMiddleButton = document.getElementById('button_select_police_middle');
selectProgramMiddleButton?.addEventListener('click', sendAnaliticsOnSelectPolisMiddleButton );

const selectProgramBottomButton = document.getElementById('button_select_police_bottom');
selectProgramBottomButton?.addEventListener('click', sendAnaliticsOnSelectPolisBottomButton );


function toggleExpandable(e:any) {
  let expcard = e.target;
  expcard = expcard.closest("div.expandable-card"); // Нажатая

  let cards = document.querySelectorAll('div.expandable-card'); //Все

  cards.forEach((card)=>{
    let cardData = card.querySelector(".card-more");
    let cardPlusIcon = card.querySelector(".plus_icon");
    let cardImg = card.querySelector("img");

    if(card === expcard){
      cardData?.classList.toggle('hidden')
      if (cardImg != null) {
        cardImg.classList.toggle("rotate_arrow");
      }
      if (cardPlusIcon != null) {
        cardPlusIcon.classList.toggle("plus_icon__minus");
      }
    }else{
      cardData?.classList.add('hidden')
      if (cardImg != null) {
        cardImg.classList.remove("rotate_arrow");
      }
      if (cardPlusIcon != null) {
        cardPlusIcon.classList.remove("plus_icon__minus");
      }
    }
  })
}


let locations = [
  ["СИТИ КЛИНИК. ул. Новокузнецкая, д. 36, ст. метро Павелецкая Часы работы Пн-Пт: с 9.00 до 22.00, Сб-Вс: с 9.00 до 21.00 тел. +7(495) 921-83-82, +7(495) 721-90-01", 55.732793, 37.634245, 4],
  ["ВИТЕРРА (КЛИНИКА № 1 БЕЛЯЕВО). ул. Профсоюзная, д. 104, ст. метро Беляево, Коньково Часы работы Пн-Пт: с 8.00 до 21.00, Сб: с 9.00 до 18.00, Вс: с 9.00 до 16.00 тел. +7(495) 120-98-00.", 55.64192, 37.523465, 4],
  ["«ПОЛИКЛИНИКА.РУ» СЕТЬ КЛИНИК. ул. Новая Басманная, д. 10, стр. 1, ст. метро Красные ворота Часы работы Пн-Пт: с 8.00 до 22.00, Сб: с 8.00 до 21.00, Вс: с 9.00 до 21.00 тел. +7(495) 127-36-97", 55.769119, 37.655877, 4],
  ["«ПОЛИКЛИНИКА.РУ» СЕТЬ КЛИНИК. 1-й Смоленский переулок, д. 17, стр. 3, ст. метро Смоленская Часы работы Пн-Пт: с 8.00 до 22.00, Сб-Вс: с 8.00 до 21.00 тел. +7(495) 127-36-97", 55.748224, 37.580068, 4],
  ["«ПОЛИКЛИНИКА.РУ» СЕТЬ КЛИНИК. Столярный переулок, д. 7, корпус 2, ст. метро Улица 1905 Часы работы Пн-Пт: с 8.00 до 22.00, Сб-Вс: с 8.00 до 21.00 тел. +7(495) 127-36-97", 55.763265, 37.566162, 4],
  ["«ПОЛИКЛИНИКА.РУ» СЕТЬ КЛИНИК. Таганская улица, дом 32/1 строение 17, ст. метро Таганская Часы работы Пн-Пт: с 8.00 до 22.00, Сб-Вс: с 9.00 до 21.00 тел. +7(495) 127-36-97", 55.739007, 37.664473, 4],
  ["«ПОЛИКЛИНИКА.РУ» СЕТЬ КЛИНИК. 1-й Кожуховский проезд, д. 9, ст. метро Автозаводская Часы работы Пн-Пт: с 8.00 до 22.00, Сб-Вс: с 9.00 до 21.00 тел. +7(495) 127-36-97", 55.706274, 37.660763, 4],
  ["«ПОЛИКЛИНИКА.РУ» СЕТЬ КЛИНИК. ул. Дорожная, д. 32, к. 1, ст. метро Академика Янгеля Часы работы ежедневно: с 8.00 до 22.00, Сб-Вс: с 9.00 до 21.00 тел. +7(495) 127-36-97", 55.591004, 37.611724, 4],
  ["«ПОЛИКЛИНИКА.РУ» СЕТЬ КЛИНИК. Комсомольский пр., дом 24, стр. 2, ст. метро Фрунзенская Часы работы Пн-Пт: с 8.00 до 22.00 тел. +7(495) 127-36-97", 55.728368, 37.580643, 4],
  ["«ПОЛИКЛИНИКА.РУ» СЕТЬ КЛИНИК. Большой Сухаревский переулок, дом 19, стр. 2, ст. метро Сухаревская Часы работы ежедневно: с 8.00 до 22.00, Круглосуточный ТРАВМПУНКТ тел. +7(495) 127-36-97 ", 55.771054, 37.629852, 4],
  ["КЛИНИКА ЦЕНТРОМЕД. Большой Афанасьевский переулок, д. 11-13, ст. метро Кропоткинская, Арбатская Часы работы: Пн-Пт: с 8.00 до 20.00 тел. +7(495) 697-56-05", 55.746213, 37.59683, 4],
  ["ПОЛИКЛИНИКА № 1 РАН. Сретенский бульвар, д. 6/1, стр. 2, ст. метро Чистые Пруды Часы работы Пн-Пт: с 8.00 до 21.00, Сб. с 9.00 до 14.00 тел. +7(495)137-74-03", 55.765924, 37.634991, 4],
  ["ФГБУ «ЛРЦ «ИЗУМРУД». Садовая - Спасская, д. 11/1, ст. метро Красные ворота Часы работы Пн-Пт: с 8.00 до 20.00, Сб. с 9.00 до 14.00 тел. +7(495) 745-28-70, +7(495) 607-99-27", 55.770603, 37.645088, 4],
  ["ФГБУЗ «ЦЕНТРАЛЬНАЯ МЕДИКО-САНИТАРНАЯ ЧАСТЬ № 119 ФЕДЕРАЛЬНОГО МЕДИКО-БИОЛОГИЧЕСКОГО АГЕНТСТВА». Сущёвский вал, д. 24, ст. метро Савёловская, Марьина роща Часы работы Пн-Пт: с 8.00 до 20.30, Сб: с 8.30 до 14.00 тел. +7(499) 972-09-46, +7(499) 972-04-00", 55.792306, 37.606397, 4],
  ["ЦЕНТРАЛЬНАЯ ПОЛИКЛИНИКА ОАО «РЖД». ул. Новая Басманная, д. 5, ст. метро Красные ворота Часы работы Пн-Пт: с 8.00 до 20.00, Сб: с 9.00 до 15.00 тел. +7(499) 262-35-99", 55.770178, 37.654017, 4],
  ["ДОБРОМЕД (СЕТЬ СЕМЕЙНЫХ КЛИНИК). ул. Братиславская, д. 18, корп. 1, ст. метро Братиславская Часы работы Пн-Пт: с 8.00 до 21.00, Сб: с 9.00 до 21.00, Вс: с 10.00 до 20.00 тел. +7(495) 045-53-03.", 55.657545, 37.759839, 4],
  ["ДОБРОМЕД (СЕТЬ СЕМЕЙНЫХ КЛИНИК). ул. Ляпидевского, д. 14, стр. 1а, ст. метро Речной вокзал Часы работы Пн-Пт: с 8.00 до 21.00, Сб: с 9.00 до 21.00, Вс: с 10.00 до 20.00 тел. +7(495) 045-53-03", 55.857417, 37.495887, 4],
  ["ДОБРОМЕД (СЕТЬ СЕМЕЙНЫХ КЛИНИК). ул. Грекова, д. 5, ст. метро Медведково Часы работы Пн-Сб: с 9.00 до 21.00, Вс: с 10.00 до 20.00 тел. +7(495) 045-53-03", 55.889376, 37.66044, 4],
  ["ДОБРОМЕД (СЕТЬ СЕМЕЙНЫХ КЛИНИК). ул. Яблочкова, д. 12, ст. метро Тимирязевская Часы работы Пн-Пт: с 8.00 до 21.00, Сб: с 9.00 до 21.00, Вс: с 10.00 до 20.00 тел. +7(495) 610-03-10, +7(965) 259-03-03", 55.81827, 37.58146, 4],
  ["ДОБРОМЕД (СЕТЬ СЕМЕЙНЫХ КЛИНИК). Коровинское шоссе, д. 23, корп. 1, ст. метро Селигерская Часы работы Пн-Пт: с 9.00 до 21.00, Сб: с 9.00 до 21.00, Вс: с 10.00 до 20.00 тел. +7(499) 906-66-11, +7(499) 906-20-02", 55.87695, 37.52342, 4],
  ["ДОБРОМЕД (СЕТЬ СЕМЕЙНЫХ КЛИНИК). г. Солнечногорск, ул. Крестьянская, д. 12 Часы работы Пн-Сб: с 8.00 до 20.00, Вс: с 10.00 до 20.00 тел. +7(495) 994-08-92, +7(495) 994-08-98", 56.184135, 36.972223, 4],
  ["МЕДИЦИНСКИЙ ЦЕНТР «СТАТУС». г. Дмитров, ул. Минина, д. 12 Часы работы: Пн-Вс: с 9.00 до 20.30 тел. +7(495) 993-80-46", 56.341588, 37.526537, 4],
  ["НИКСОР КЛИНИК (СЕТЬ МЕДИЦИНСКИХ ЦЕНТРОВ). г. Долгопрудный, Лихачевский проспект, д. 76, корп. 1 Часы работы: Пн-Сб: с 8.00 до 20.00, Вс: с 9.00 до 16.00 тел. +7(925) 844-90-03", 55.936474, 37.494072, 4],
  ["НИКСОР КЛИНИК (СЕТЬ МЕДИЦИНСКИХ ЦЕНТРОВ). г. Химки, ул. Молодёжная, д. 52 Часы работы: Пн-Сб: с 8.00 до 20.00, Вс: с 9.00 до 16.00 тел. +7(929) 546-88-03", 55.900824, 37.40009, 4],
  ["ГОРОДСКОЙ МЕДИЦИНСКИЙ ЦЕНТР В МЫТИЩАХ. г. Мытищи, ул. Рождественская, д. 7 Часы работы: Круглосуточно тел. +7(495) 588-08-80", 55.919639, 37.753775, 4],
  ["МЕД ГАРАНТ (МЕДИЦИНСКИЙ ЦЕНТР). г. Балашиха, мкр. Саввино, ул. 1 Мая, дом № 2, корп. 1 Часы работы: Пн-Пт: с 7.30 до 20.00, Сб: с 8.00 до 20.00, Вс: с 9.00 до 20.00 тел. +7(495) 369-60-91", 55.727024, 38.019883, 4],
  ["СЕМЕЙНАЯ ПОЛИКЛИНИКА № 4. г. Королев, мкр. Болшево, ул. Станционная, д. 33 Часы работы: Пн-Сб: с 7.00 до 21.00, Вс: с 8.00 до 21.00 тел. 8(800) 555-09-09", 55.932037, 37.858815, 4],
  ["СЕМЕЙНАЯ ПОЛИКЛИНИКА № 3. г. Пушкино, ул. Тургенева, д. 13 Часы работы: Пн-Пт: с 8.00 до 20.00, Сб: с 8.00 до 19.00, Вс: с 8.00 до 17.00 тел. 8(800) 555-09-09 ", 56.011852, 37.852509, 4],
  ["Стоматология - «Стоматологическая клиника «ДОКТОР СМАЙЛ».	ул. Новая Басманная, д. 29, стр. 1, ст. метро Красные ворота Часы работы Пн-Вс с 9.00 до 21.00 Тел. 8(499) 261-15-25; 8(499) 261-09-83, Единый информационный центр 8(495) 229-14-24, 8(495) 134-35-03 Терапия, хирургия, ортопедия, ортодонтия. 5 стоматологических установок", 55.769914, 37.664608, 4],
  ["Стоматология - «Стоматологическая клиника «ДОКТОР СМАЙЛ». Руновский переулок, д.11/13, ст. метро Новокузнецкая Часы работы Пн-Вс с 9.00 до 21.00 Тел. 8(495) 953-86-51, Единый информационный центр 8(495) 229-14-24, 8(495) 134-35-03 Терапия, хирургия, ортопедия, ортодонтия. 3 стоматологических установки", 55.742093, 37.632134, 4],
];

/* Инициализация карты */
let clinicsMap:any;
let mapLatitude = 55.76;
let mapLongitude = 37.64;
let mapZoom =  user_city === "Москва" || user_city === "" || user_city === undefined ? 8 : 10

await ymaps.ready(mapInit);

function mapInit() {
  var i;
  var place;

  clinicsMap = new ymaps.Map(
    "map",
    {
      center: [mapLatitude, mapLongitude],
      zoom: mapZoom,
      controls: ["zoomControl"],
    },
    {
      suppressMapOpenBlock: true,
      suppressObsoleteBrowserNotifier: true,
    }
  );

  for (i = 0; i < locations.length; ++i) {
    place = new ymaps.Placemark(
      [locations[i][1], locations[i][2]],
      {
        address: locations[i][0],
      },
      {
        iconLayout: "default#image",
        iconImageHref: "images/marker.png",
        iconImageSize: [32, 48],
      }
    );
    clinicsMap.geoObjects.add(place);
    place.events.add("click", function (e) {
      var target = e.get("target");

      let map_info = document.querySelector(".map_info");
      map_info.innerHTML = target.properties.get("address");
    });
  }
}

function ownMapMarkerInit() {
  if (mapMarkersListen) {
    return
  }
  let marker1 = document.getElementById("mapMarkerSP");
  if (marker1) {
    marker1.addEventListener("click", ()=>{selectCity('Санкт-Петербург')}, false);
  }
  let marker2 = document.getElementById("mapMarkerMos")
  if (marker2) {
    marker2.addEventListener ("click", ()=>{selectCity('Москва')}, false);
  }
  let marker3 = document.getElementById("mapMarkerEkb");
  if (marker3) {
    marker3.addEventListener("click", ()=>{selectCity('Екатеринбург')}, false);
  }
  let marker4 = document.getElementById("mapMarkerChb")
  if (marker4) {
    marker4.addEventListener ("click", ()=>{selectCity('Челябинск')}, false);
  }
  let marker5 = document.getElementById("mapMarkerNB");
  if (marker5) {
    marker5.addEventListener("click", ()=>{selectCity('Новосибирск')}, false);
  }
  mapMarkersListen = true;
}

let toggle_menu_btn = document.querySelector(".toggle_menu");
let menu = document.querySelector(".main-menu");
toggle_menu_btn.addEventListener("click", function () {
  menu.classList.toggle("hidden");
});

/* ПОДПИСКА НА НОВОСТИ */
let notificate_form = document.querySelector(".subscribe_to_notification .notificate_form");
let notificate_form_success = document.querySelector(".subscribe_to_notification .success_form");
let subscribe_form = document.getElementById("subscribe_form");
let subscribe_form_input = subscribe_form.querySelector("input");
let subscribe_form_error = subscribe_form.querySelector("small");
subscribe_form.addEventListener("submit", function (e) {
  e.preventDefault();

  if (subscribe_form_input.value == "") {
    subscribe_form_error.innerHTML = "Введите Email";
    subscribe_form_input.classList.add("input_error");
    return;
  }
  subscribe_form_input.classList.remove("input_error");
  if (!validateEmail(subscribe_form_input.value)) {
    subscribe_form_error.innerHTML = "Введите корректный Email";
    subscribe_form_input.classList.add("input_error");
    return;
  }
  const subscribe_url = process.env.SUBSCRIBE_URL;

  axios
    .post(subscribe_url, {
      name: city_clicked_by_user,
      email_or_phone: subscribe_form_input.value,
      message: "Сообщить об открытии",
    })
    .then(
      (response:any) => {
      },
      (error:any) => {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
      }
    );

  subscribe_form_input.classList.remove("input_error");
  subscribe_form_error.innerHTML = "";
  notificate_form.classList.add("hidden");
  notificate_form_success.classList.remove("hidden");
  subscribe_form_input.value = "";
});

/* Фидбек */
let form_container = document.querySelector(".contact_form .form_container");
let success_form = document.querySelector(".contact_form .success_form");
let feedback_form = document.getElementById("feedback_form");
let feedback_form_name = feedback_form.querySelector("input[name='name']");
let feedback_form_name_error = feedback_form.querySelector("small.name_error");
let feedback_form_email = feedback_form.querySelector("input[name='email']");
let feedback_form_message = feedback_form.querySelector("input[name='message']");
let feedback_form_email_error = feedback_form.querySelector("small.email_error");
let feedback_form_message_error = feedback_form.querySelector("small.message_error");
let ask_question_btn = document.querySelector(".ask_question_btn");
let send_question_btn = document.querySelector(".send_question_btn");

feedback_form?.addEventListener("submit", function (e) {
  e.preventDefault();
  if ( process.env.MODE !== 'development') {
    ym(89551236, "reachGoal", "send_question_btn");
  }
  if (feedback_form_name.value == "") {
    feedback_form_name_error.innerHTML = "Введите Имя";
    feedback_form_name.classList.add("input_error");
    return;
  }
  
  feedback_form_name.classList.remove("input_error");
  if (feedback_form_name.value.length < 3) {
    feedback_form_name_error.innerHTML = "Не меньше 3-х символов";
    feedback_form_name.classList.add("input_error");
    return;
  }
  feedback_form_name.classList.remove("input_error");
  feedback_form_name_error.innerHTML = "";
  
  if (feedback_form_email.value == "") {
    feedback_form_email_error.innerHTML = "Введите Email";
    feedback_form_email.classList.add("input_error");
    return;
  }
  if (!validateEmail(feedback_form_email.value)) {
    feedback_form_email_error.innerHTML = "Введите корректный Email";
    feedback_form_email.classList.add("input_error");
    return;
  }
  feedback_form_email.classList.remove("input_error");
  feedback_form_email_error.innerHTML = "";

  if (feedback_form_message.value == "") {
    feedback_form_message_error.innerHTML = "Введите сообщение";
    feedback_form_message.classList.add("input_error");
    return;
  }
  feedback_form_message.classList.remove("input_error");
  if (feedback_form_message.value.length <= 5) {
    feedback_form_message_error.innerHTML = "Ваше сообщение слишком короткое";
    feedback_form_message.classList.add("input_error");
    return;
  }
  feedback_form_message.classList.remove("input_error");
  feedback_form_message_error.innerHTML = "";

  const request_url = process.env.REQUEST_URL;

  send_question_btn.disabled = true;
  send_question_btn.querySelector("span").classList.add("hidden");
  send_question_btn.querySelector(".spin-loader").classList.remove("hidden");

  axios
    .post(request_url, {
      name: feedback_form_name.value,
      email_or_phone: feedback_form_email.value,
      message: `${city_clicked_by_user}: ${feedback_form_message.value}`,
    })
    .then(
      (response) => {
        send_question_btn.disabled = false;
        send_question_btn.querySelector("span").classList.remove("hidden");
        send_question_btn.querySelector(".spin-loader").classList.add("hidden");
      },
      (error) => {
        send_question_btn.disabled = false;
        send_question_btn.querySelector("span").classList.remove("hidden");
        send_question_btn.querySelector(".spin-loader").classList.add("hidden");
        console.log('====================================');
        console.log(error);
        console.log('====================================');
      }
    );

  form_container.classList.add("hidden");
  success_form.classList.remove("hidden");
});

ask_question_btn?.addEventListener("click", function (e) {
  send_question_btn.disabled = false;
  send_question_btn.querySelector("span").classList.remove("hidden");
  send_question_btn.querySelector(".spin-loader").classList.add("hidden");
  feedback_form_name.value = "";
  feedback_form_email.value = "";
  feedback_form_message.value = "";
  form_container.classList.remove("hidden");
  success_form.classList.add("hidden");
});

function validateEmail(email:string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

let links_prevent = document.querySelectorAll(".link_prevent");

links_prevent.forEach((link) => {
  link.addEventListener("click", function (e) {
    e.stopPropagation();
  });
});

// собираем все якоря; устанавливаем время анимации и количество кадров
const anchors = [].slice.call(document.querySelectorAll('a[href*="#"]')),
  animationTime = 600,
  framesCount = 60;

anchors.forEach(function (item:any) {
  // каждому якорю присваиваем обработчик события
  item.addEventListener("click", function (e:any) {
    // if(item.getAttribute("href") == "#moi_dms_1" || item.getAttribute("href") == "#moi_dms_2" || item.getAttribute("href") == "#moi_dms_3" || item.getAttribute("href") == "#moi_dms_4"){
    //   e.preventDefault();
    //   return;
    // }
    // убираем стандартное поведение
    e.preventDefault();

    // для каждого якоря берем соответствующий ему элемент и определяем его координату Y
    let coordY = document.querySelector(item.getAttribute("href")).getBoundingClientRect().top + window.pageYOffset;

    // запускаем интервал, в котором
    let scroller = setInterval(function () {
      // считаем на сколько скроллить за 1 такт
      let scrollBy = coordY / framesCount;

      // если к-во пикселей для скролла за 1 такт больше расстояния до элемента
      // и дно страницы не достигнуто
      if (scrollBy > window.pageYOffset - coordY && window.innerHeight + window.pageYOffset < document.body.offsetHeight) {
        // то скроллим на к-во пикселей, которое соответствует одному такту
        window.scrollBy(0, scrollBy);
      } else {
        // иначе добираемся до элемента и выходим из интервала
        window.scrollTo(0, coordY);
        clearInterval(scroller);
      }
      // время интервала равняется частному от времени анимации и к-ва кадров
    }, animationTime / framesCount);
  });
});

let programs = [];
let currentProgramID = 0;

function toggleTab(e:any) {
  let tabId = e.target.getAttribute("data-tab");
  if (currentProgramID === parseInt(tabId)) {
    return
  }
  let tabs = document.querySelectorAll(".tab");

  tabs.forEach((tab) => {
    tab.classList.remove("tab-active");
  });

  e.target.classList.add("tab-active");

  const dmsInfo = document.getElementById('dms_info');
  if (dmsInfo === undefined || dmsInfo === null) {
    return;
  }
  dmsInfo.innerHTML = '';
  const dmsDescription = document.getElementById('dms_description');
  if (dmsDescription === undefined || dmsDescription === null) {
    return;
  }
  dmsDescription.innerHTML = '';

  const findedProgram = programs.find(({id}) => {
    return parseInt(id) === parseInt(tabId)
  })
  rowRemoveEventListener()
  currentProgramID = findedProgram.id
  const program = new Program(findedProgram);
  program.render();
  rowAddEventListener()
}


function rowRemoveEventListener() {
  const expandables = document.querySelectorAll(".expandable-card");
  expandables.forEach((expandable) => {
    expandable.removeEventListener('click', toggleExpandable);
  });
}

function rowAddEventListener() {
  const expandables = document.querySelectorAll(".expandable-card");
  expandables.forEach((expandable) => {
    expandable.addEventListener("click", toggleExpandable);
  });
}

/* Отображение программ в зависимости от города */
async function showPrograms() {
  const list = document.getElementById("programs_list")
  if (list === undefined || list === null) {
    return
  }
  list.innerHTML = '';
  rowRemoveEventListener();
  list.appendChild(document.createTextNode("Идет загрузка программ ДМС для вашего города... Подождите"))

  const dmsInfo = document.getElementById('dms_info');
  if (dmsInfo === undefined || dmsInfo === null) {
    return;
  }

  if ( dataDMS === undefined || dataDMS  === null || dataDMS.length === 0) {
    dataDMS = await getCityData( user_city || 'Москва' );
  }
  if (dataDMS?.data?.dms_cities===undefined) {
    return
  }
  /* Сохраняем полученные программы и сортируем по ID. Первую из списка делаем активной */
  programs = dataDMS.data.dms_cities[0].products.sort((a:any,b:any) => {
    if (a.id<b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  })
  if ( programs.length === 0 ) {
    return
  }
  if (programs === undefined && programs === null && programs.length === 0) {
    return
  }
  list.innerHTML = '';

  // Обовляем баннер с отображением минимальной суммы
  const minSumSpan = document.getElementById("insurance_min_price");
  if (minSumSpan !== undefined && minSumSpan !== null) {
    minSumSpan.innerHTML="";
    const newMinSum = programs[0].price[0].price;
    minSumSpan.appendChild(document.createTextNode(`от ${numberToStringWithSpaces(newMinSum,',')} ₽`))
  }

  /* Отображаем вкладки для программ */
  programs.map((item:any, index:number) => {
    // console.log("PROGRAM :", index, item)
    const aTag = document.createElement("a");
    aTag.classList.add("flex-none");
    // aTag.href=`#moi_dms_${index+1}`;
    aTag.dataset.test=`test_dms_${index+1}_program`;
    const li = document.createElement("li");
    li.classList.add('tab', 'cursor-pointer', 'flex-none');
    /* Событие смены вкладки */
    li.addEventListener("click", toggleTab);
    if (index === 0) {
      li.classList.add('tab-active');
    }
    li.dataset.tab=item.id;
    li.appendChild(document.createTextNode(`«${item.name}»`));
    aTag.appendChild(li);

    list.appendChild(aTag);
  })

  dmsInfo.style.display = 'block';
  dmsInfo.innerHTML = '';
  const dmsDescription = document.getElementById('dms_description');
  if (dmsDescription === undefined || dmsDescription === null) {
    return;
  }
  dmsDescription.innerHTML = '';

  /* Отображаем выбранную программу */
  currentProgramID = programs[0].id
  const program = new Program(programs[0]);
  program.render();
  
  rowAddEventListener();

  const clinics = await getClinicsData(user_city || 'Москва');
  const {dmsGetInfoClinicForMap} = clinics?.data;
  const mapSubtitle = document.getElementById("subtitle_clinics_map");
  if (mapSubtitle) {
    mapSubtitle.innerHTML = "";
    const countClinicsTag = document.createElement("b");
    countClinicsTag.appendChild(document.createTextNode(`${dmsGetInfoClinicForMap?.infoClinic.length || 30} клиник`))
    mapSubtitle.appendChild(countClinicsTag);
    mapSubtitle.appendChild(document.createTextNode(` по городу ${user_city==="Москва" ? "Москва и МО" : user_city} готовы принимать Вас ежедневно`))
  }
  if (dmsGetInfoClinicForMap) {
    let tmpLatitude = 0;
    let tmpLongitude = 0;
    let tmpCount = 0;
    locations = dmsGetInfoClinicForMap?.infoClinic.map((item:any) => {
      const row = `${item.name}. ${item.address}. ${item.working_hours} ${item.phone}`
      const lat = parseFloat(item.latitude);
      const lon = parseFloat(item.longitude);
      if (lat && lat !== 0 && lon && lon !== 0) {
        tmpLatitude += parseFloat(item.latitude);
        tmpLongitude += parseFloat(item.longitude);
        tmpCount++;
      }
      return [row, item.latitude, item.longitude, 4]
    })
    mapLatitude = tmpLatitude / tmpCount;
    mapLongitude = tmpLongitude / tmpCount;
    mapZoom = user_city === "Москва" ? 8 : 10
    if (clinicsMap) {
      clinicsMap.destroy();
    }
    mapInit()

    ownMapMarkerInit();

  }
}

showPrograms()

// Bitrix24 Support
const bitrix24Init = async () => {
  await loadBitrix24(
    window,
    document,
    "https://cdn-ru.bitrix24.ru/b22464750/crm/site_button/loader_3_rqx5eh.js"
  );
}
bitrix24Init();

if (process.env.MODE !== "development") {
  // Yandex.Metrika counter
  const ymInit = async () => {
    await loadYM(
      window,
      document,
      "script",
      "https://mc.yandex.ru/metrika/tag.js",
      "ym"
    );
    ym(89551236, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      ecommerce: "dataLayer",
    });
  }
  ymInit();

  // Mail.Ru Metrika
  const mailRuInit = async () => {
    await loadMailRu(document, window, "tmr-code");
  }
  mailRuInit();

  // DirectAdvert Metrika
  const daInit = async () => {
    await loadDA(document, "img", "https://code.directadvert.ru/track/2243210.gif");
  }
  daInit();

  // Google Metrika
  const googleInit = async () => {
    await loadGoogle(window, document, "script", "dataLayer", "GTM-KPZPHGG");
  }
  googleInit();
}

function sendAnaliticsOnSelectPolisTopButton() {
  if (process.env.MODE === "development") return
  VK.Retargeting.Event('all_program');
  VK.Retargeting.Add(49525474);
  ym(89551236,'reachGoal','all_program');
}

function sendAnaliticsOnSelectPolisMiddleButton() {
  if (process.env.MODE === "development") return
  VK.Retargeting.Event('all_program');
  VK.Retargeting.Add(49525474);
  ym(89551236,'reachGoal','all_program');
}

function sendAnaliticsOnSelectPolisBottomButton() {
  if (process.env.MODE === "development") return
}