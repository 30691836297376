import request from "sync-request";

//ip = "46.226.227.20";

async function getIp() {
  try {
    const res = await request("GET", "https://icanhazip.com");
    return res.getBody();
  } catch (error) {
    console.log('====================================');
    console.log(error);
    console.log('====================================');
  }
}


async function getCityInformationByIp() {
  try {
    const ip = await getIp();
    let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address?ip=";
    const token = "40ab8f2fec20786e6fd689b3ace8ee2cc424c9e8";
    const res = await request("GET", url + ip, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token " + token,
      },
    });
    return res.getBody();
  } catch(error) {
    console.log('====================================');
    console.log(error);
    console.log('====================================');
  }
}
export { getIp, getCityInformationByIp }