class ItemRow {
  #id;
  #programId;
  #description;
  #hint;
  #listMedicalInstitutions;
  #title
  constructor(id:number, programId:number, info:any) {
    this.#id = id;
    this.#programId = programId;
    this.#description = info.description;
    this.#hint = info.hint;
    this.#title = info.title;
    this.#listMedicalInstitutions = info.listMedicalInstitutions;
  }
    
  render() {
    const divTag = document.createElement('div');
    divTag.classList.add('card','text-left','flex',
      'flex-row','w-full','justify-between','items-start','mb-4','cursor-pointer','expandable-card');
    divTag.style.paddingLeft = "40px"
    divTag.style.paddingRight = "40px"
    divTag.style.paddingTop = "24px"
    divTag.style.paddingBottom = "24px"
      
    divTag.setAttribute('data-test-label',`test_program_description`)
    const divCard = document.createElement('div');

    const title = document.createElement('h3');
    title.style.cssText += 'text-align:left';
    title.classList.add('text-em-dark','mb-4', );
    title.appendChild(document.createTextNode(this.#title))

    const subtitle = document.createElement('p');
    subtitle.appendChild(document.createTextNode(this.#hint));

    divCard.appendChild(title);
    divCard.appendChild(subtitle);

    const divProgramContent = document.createElement('div');
    divProgramContent.classList.add('card-more','mt-8','hidden');
    divProgramContent.setAttribute('data-test-label',`test_program_description_content`)

    this.#description.forEach((description:any, index:number) => {
      const divProgramContentText = document.createElement('p');
      divProgramContentText.classList.add('mt-3');
      divProgramContentText.setAttribute('data-test-label',`test_program_${this.#programId}_description_${this.#id}_row_${index+1}_text`)
      divProgramContentText.appendChild(document.createTextNode(description.title))
      divProgramContent.appendChild(divProgramContentText);
      
      const descritionList = document.createElement('ul');
      descritionList.classList.add('simple-list','mt-3');
      description.items.forEach((item:any) => {
        const rowDescription = document.createElement('li');
        rowDescription.appendChild(document.createTextNode(item))
        descritionList.appendChild(rowDescription);
      })

      divProgramContent.appendChild(descritionList);
    })

    divCard.appendChild(divProgramContent);

    const divArrow = document.createElement('div');
    divArrow.classList.add('w-[150px]','relative');
    const imgArrow = document.createElement('img');
    imgArrow.classList.add('ml-auto','mt-6');
    imgArrow.setAttribute('alt','arrow');
    imgArrow.setAttribute('src','images/right.png');
    divArrow.appendChild(imgArrow);

    divTag.appendChild(divCard);
    divTag.appendChild(divArrow);

    const divDecscription = document.getElementById('dms_description')
    if (divDecscription === undefined || divDecscription === null) {
      return;
    }
    divDecscription.appendChild(divTag);
  }
}
  
export default ItemRow