
function renderContactRow(clinicBlock:HTMLElement, label:string, value:string, styles:Array<string>) {
	if (value === '') {
		return;
	}
	const clinicInfoBlock = document.createElement('div');
	styles.map(style => {
			clinicInfoBlock.classList.add(style);
	})
	const clinicInfoSpan = document.createElement('span');
	clinicInfoSpan.classList.add('block','text-md')
	clinicInfoSpan.appendChild(document.createTextNode(label))

	clinicInfoBlock.appendChild(clinicInfoSpan);

	const clinicInfoP = document.createElement('p');
	clinicInfoP.appendChild(document.createTextNode(value))

	clinicInfoBlock.appendChild(clinicInfoP);

	clinicBlock.appendChild(clinicInfoBlock);
}

function addClinic(clinicBlock:HTMLElement, institution:any) {
	const clinicTitle = document.createElement('span');
	clinicTitle.classList.add('text-md','uppercase','block','text-[#2E3951]');
	clinicTitle.appendChild(document.createTextNode(institution.name))
	
	clinicBlock.appendChild(clinicTitle);

	if (institution.items.length < 2) {
		renderContactRow(clinicBlock, 'Адрес:', institution.items[0].address || '', ['mt-6']);
		renderContactRow(clinicBlock, 'Часы работы:', institution.items[0].working_hours || '', ['mt-4']);
		renderContactRow(clinicBlock, 'Телефон:', institution.items[0].phone || '', ['mt-4','mb-6']);
	} else {
		const multipleInfoBlock = document.createElement('div');
		multipleInfoBlock.classList.add('grid','grid-cols-1','md:grid-cols-2','gap-0','md:gap-6','w-full');
		institution.items.map((item:any) => {
				const block = document.createElement('div');
				renderContactRow(block, 'Адрес:', item.address || '', ['mt-6']);
				renderContactRow(block, 'Часы работы:', item.working_hours || '', ['mt-4']);
				renderContactRow(block, 'Телефон:', item.phone || '', ['mt-4','mb-6']);
				multipleInfoBlock.appendChild(block);
		})
		clinicBlock.appendChild(multipleInfoBlock);
	}
}

export { addClinic }