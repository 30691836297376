const axios = require("axios").default;

const GRAPHQL_API_PATH = `${process.env.GRAPHQL_API_PATH}?timestamp=${new Date().getTime()}`

const getCitiesQuery = () => `
query Cities { 
  dms_cities {
    name
    active
    position
} }`;

const getCityDataQuery = (city:string) => `
  query Cities { 
    dms_cities(where: { name: {_eq: "` + city + `"}}) {
      products {
        id
        name
        price
        program
        medical_sum
        file_program_path
      }
  } }`;

const getClinicsQuery = (city:string) =>`
  query dmsGetInfoClinicForMap {
    dmsGetInfoClinicForMap(arg: {
      city_name: "` + city + `"
    }) {
      infoClinic
    }
  }
`

async function getCities() {
  const r = await axios
  .post(GRAPHQL_API_PATH,JSON.stringify({
      query: getCitiesQuery()
    }), {
      headers: {
          "Content-Type": "application/json"
        },
    })
  .then(
    (response:any) => {
      return response.data
    },
    (error:any) => {
      console.log('====================================');
      console.log(error);
      console.log('====================================');
    }
  );
  return r;
}

async function getCityData(city:string) {
  const r = await axios
    .post(GRAPHQL_API_PATH,JSON.stringify({
        query: getCityDataQuery(city)
      }), {
        headers: {
            "Content-Type": "application/json"
          },
      })
    .then(
      (response:any) => {
        return response.data
      },
      (error:any) => {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
      }
    );
  return r;
}

async  function getClinicsData(city:string) {
  const r = await axios
    .post(GRAPHQL_API_PATH,JSON.stringify({
        query: getClinicsQuery(city)
      }), {
        headers: {
            "Content-Type": "application/json"
          },
      })
    .then(
      (response:any) => {
        return response.data
      },
      (error:any) => {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
      }
    );
  return r;
}

export { getCities, getCityData, getClinicsData }