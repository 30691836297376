import { loadYM  } from "./metrika/ym"
import { loadMailRu } from './metrika/mailRu'
import { loadDA } from './metrika/directadvert'
import { loadBitrix24 } from './support/bitrix24'
import { loadGoogle } from './metrika/google'

function numberToStringWithSpaces( value: number, symbol=' ' ) {
  const result = String( value ).replace( /(\d)(?=(\d{3})+([^\d]|$))/g, `$1${symbol}` )
  return result
}

export { numberToStringWithSpaces, loadYM, loadMailRu, loadDA, loadBitrix24, loadGoogle }